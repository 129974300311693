import * as R from "ramda";

// DB Schema:
// status_OPEN int = 0;
// status_PROCESS int = 1;
// status_DONE int = 10;
// status_DONE_N_A int = 11;
// status_DONE_OPT_OUT int = 12;
// status_N_A int = 20

// Statuses Greater than 10 will be considered "Done"
// i.e. `complete = status >= 10`
export enum OnboardingStatusEnum {
  Open = 0,
  Processing = 1,
  Done = 10,
  Done_NA = 11,
  OptOut = 12,
  N_A = 20,
}

type BaseOnboardingStep = {
  status: OnboardingStatusEnum;
  countable: boolean; // indicates whether this step should contribute the displayed progress of "completed" steps
  hidden?: boolean; // indicates whether this step should render an `OnboardingGuideStep` in the onboarding guide
  readOnly?: boolean; // todo document field
  informational?: boolean; // todo document field
  omitFromCountIfOptedOut?: boolean; // omit from group and total count if step is opted out. still show step in menu with opted out icon.
  notClickableInMenuWhenComplete?: boolean; // Prevents Tooltip from opening when the corresponding onboarding step in the guide is clicked if the step is complete
};

// Constraint - onboarding steps should have EITHER
//  - an `itemId` to determine their status
//  - OR any number of `subSteps` from which an aggregate status is calculated
type PartialOnboardingStep<
  SubStep extends PartialOnboardingSubStep = PartialOnboardingSubStep
> = BaseOnboardingStep &
  (
    | {
        stepType: "aggregateStep";
        listSubSteps?: boolean; // Replacing functionality of aggregateStatusIds
        subSteps: {
          [key: string]: SubStep;
        };
      }
    | {
        stepType: "step";
        itemId: number;
      }
  );

type PartialOnboardingSubStep = {
  status: OnboardingStatusEnum;
  countable: boolean; // indicates whether this step should contribute the displayed progress of "completed" steps
  hidden?: boolean; // indicates whether this step should render an `OnboardingGuideStep` in the onboarding guide
  itemId: number;
  readOnly: boolean;
  omitFromCountIfOptedOut?: boolean;
  stepType: "subStep";
};

export type OnboardingSubStep = PartialOnboardingSubStep & {
  pathId: string;
  nextPathId?: string;
  complete: boolean;
};

type OnboardingStepTypeGeneratedParts = {
  pathId: string;
  nextPathId?: string;
  complete: boolean;
  itemId: number;
};

export type OnboardingStepType<
  SubStep extends PartialOnboardingSubStep = PartialOnboardingSubStep
> = PartialOnboardingStep<SubStep> & OnboardingStepTypeGeneratedParts;

const defaultBool = (value: boolean | undefined, d: boolean) =>
  value === undefined ? d : value;

export const defaultStep = (
  itemId: number,
  options?: {
    countable?: boolean;
    hidden?: boolean;
    readOnly?: boolean;
    notClickableInMenuWhenComplete?: boolean;
    informational?: boolean;
    omitFromCountIfOptedOut?: boolean;
  }
): PartialOnboardingStep => ({
  status: OnboardingStatusEnum.Open,
  // complete: false,
  itemId,
  countable: defaultBool(options?.countable, true),
  hidden: defaultBool(options?.hidden, false),
  readOnly: defaultBool(options?.readOnly, false),
  notClickableInMenuWhenComplete: defaultBool(
    options?.notClickableInMenuWhenComplete,
    true
  ),
  informational: defaultBool(options?.informational, false),
  omitFromCountIfOptedOut: defaultBool(options?.omitFromCountIfOptedOut, true),
  stepType: "step",
});

export const defaultSubStep = (
  itemId: number,
  options?: {
    countable?: boolean;
    hidden?: boolean;
    readOnly?: boolean;
    omitFromCountIfOptedOut?: boolean;
  }
): PartialOnboardingSubStep => ({
  status: OnboardingStatusEnum.Open,
  // complete: false,
  itemId,
  countable: defaultBool(options?.countable, false),
  hidden: defaultBool(options?.hidden, false),
  readOnly: defaultBool(options?.readOnly, false),
  stepType: "subStep",
  omitFromCountIfOptedOut: defaultBool(options?.omitFromCountIfOptedOut, true),
});

// Level one is "onboarding group"
// Level two is "onboarding step"

// All "schema" objects should `implement` this type (hopefully we can use the `implements` keyword asap).
type SchemaShape = {
  [group: string]: {
    [step: string]: PartialOnboardingStep;
  };
};

export const completenessSchema = {
  overview: {
    uploadBuildingPhoto: defaultStep(35),
    buildingName: {
      status: OnboardingStatusEnum.Open,
      countable: true,
      notClickableInMenuWhenComplete: true,
      stepType: "aggregateStep",
      subSteps: {
        name: defaultSubStep(1),
        legalName: defaultSubStep(33),
      },
    },
    address: defaultStep(2),
    size: {
      status: OnboardingStatusEnum.Open,
      notClickableInMenuWhenComplete: true,
      countable: true,
      stepType: "aggregateStep",
      subSteps: {
        gross: defaultSubStep(3),
      },
    },
    buildingType: defaultStep(5),
    yearConstructed: defaultStep(6),
    systemOfRecord: defaultStep(37, { countable: false, informational: true }),

    ownership: defaultStep(7),
    vendors: defaultStep(8),
  },
  utilities: {
    enterElectricityUtility: defaultStep(9),
    enterWasteUtility: defaultStep(10),
    enterWaterUtility: defaultStep(11),
    enterNaturalGasUtility: defaultStep(12),
    enterDistrictHeatingUtility: defaultStep(14),
    enterDistrictCoolingUtility: defaultStep(13),
  },
  submissions: {
    linkEnergyStarAccount: defaultStep(15),
    addOtherIntegrations: {
      status: OnboardingStatusEnum.Open,
      notClickableInMenuWhenComplete: true,
      omitFromCountIfOptedOut: true,
      countable: true,
      stepType: "aggregateStep",
      subSteps: {
        leed: defaultSubStep(16),
        gresb: defaultSubStep(17),
      },
    },
  },
  buildingDesign: {
    uploadBuildingPlans: {
      stepType: "aggregateStep",
      listSubSteps: true,
      status: OnboardingStatusEnum.Open,
      notClickableInMenuWhenComplete: true,
      countable: false,
      subSteps: {
        mechanical: defaultSubStep(18, {
          countable: true,
          readOnly: true,
        }),
        electrical: defaultSubStep(19, {
          countable: true,
          readOnly: true,
        }),
        structural: defaultSubStep(20, {
          countable: true,
          readOnly: true,
        }),
        architectural: defaultSubStep(21, {
          countable: true,
          readOnly: true,
        }),
        plumbing: defaultSubStep(22, {
          countable: true,
          readOnly: true,
        }),
      },
    },
  },
  projects: {
    uploadCapitalPlan: defaultStep(23),
    addAdditionalProjects: defaultStep(24),
  },
  leasing: {
    uploadLeasingDocuments: defaultStep(25),
  },
} satisfies SchemaShape;

export const onSiteSchema = {
  gatewayInstall: {
    gatewayInstallComplete: defaultStep(26, {
      omitFromCountIfOptedOut: false,
      notClickableInMenuWhenComplete: false,
    }),
    BASIntegration: defaultStep(28, {
      omitFromCountIfOptedOut: false,
      notClickableInMenuWhenComplete: false,
    }),
  },
  intervalData: {
    APIConnected: defaultStep(29, { readOnly: true }),
    installPulseMeter: defaultStep(30, { readOnly: true }),
  },
  backpackSiteVisit: {
    scheduleInitialSiteVisit: defaultStep(31),
    scheduleFollowUpVisit: defaultStep(32),
  },
} satisfies SchemaShape;

type WithSubSteps = {
  subSteps: unknown;
};

type Empty = {};

type StepShape<
  Keys extends WithSubSteps | Empty,
  Step extends PartialOnboardingStep,
  SubStep extends PartialOnboardingSubStep
> = Keys extends WithSubSteps
  ? Step & {
      subSteps: {
        [key in keyof Keys["subSteps"]]: SubStep;
      };
    }
  : Step;

export type PathShape<
  Schema extends {
    [k1: string]: {
      [k2: string]:
        | {
            subSteps: unknown;
          }
        | {};
    };
  },
  Step extends PartialOnboardingStep,
  SubStep extends PartialOnboardingSubStep
> = {
  [P in keyof Schema]: {
    [P2 in keyof Schema[P]]: StepShape<Schema[P][P2], Step, SubStep>;
  };
};

function generatePathIds<Schema extends SchemaShape>(
  input: PathShape<Schema, PartialOnboardingStep, PartialOnboardingSubStep>
) {
  let previousStepRef: Record<string, any> = {};

  return R.mapObjIndexed((group, groupId) => {
    const a = R.mapObjIndexed((step: PartialOnboardingStep, stepId) => {
      const newPathId = `${groupId}.${stepId}`;
      let subSteps;
      if (step.stepType === "aggregateStep") {
        subSteps = R.mapObjIndexed((subStep, subStepId) => {
          const newSubStepPathId = `${groupId}.${stepId}.${subStepId}`;
          const currentSubStep = {
            ...subStep,
            pathId: newSubStepPathId,
          };
          // Memory reference trick to set value on the previously referenced object
          previousStepRef.nextPathId = newSubStepPathId;
          previousStepRef = currentSubStep;

          return currentSubStep;
        }, step.subSteps);
      }
      const currentStep =
        step.stepType === "aggregateStep"
          ? {
              ...step,
              pathId: newPathId,
              subSteps,
            }
          : ({
              ...step,
              pathId: newPathId,
            } as OnboardingStepType);

      if (step.stepType === "step") {
        // Memory reference trick to set value on the previously referenced object
        previousStepRef.nextPathId = newPathId;
        previousStepRef = currentStep;
      }

      return currentStep;
    }, group);
    return a;
  }, input) as PathShape<
    Schema,
    OnboardingStepType<OnboardingSubStep>,
    OnboardingSubStep
  >;
}

// add nextPathIds to readonly parent steps
const fillInParentPathIds = <Schema extends SchemaShape>(
  schema: PathShape<
    Schema,
    OnboardingStepType<OnboardingSubStep>,
    OnboardingSubStep
  >
) =>
  R.mapObjIndexed(
    (group) =>
      R.map(
        (step) =>
          step.stepType === "aggregateStep" &&
          Object.values(step.subSteps).every((subStep) => subStep.readOnly)
            ? {
                ...step,
                nextPathId: Object.values(step.subSteps)[
                  Object.values(step.subSteps).length - 1
                ]?.nextPathId,
              }
            : step,
        group
      ),
    schema
  ) as PathShape<
    Schema,
    OnboardingStepType<OnboardingSubStep>,
    OnboardingSubStep
  >;

export function generateFinalPathIds<Schema extends SchemaShape>(
  input: PathShape<Schema, PartialOnboardingStep, PartialOnboardingSubStep>
) {
  return fillInParentPathIds(generatePathIds(input));
}

// onboarding schema requires hardcoded steps

export const getMultifamilySchema = () => {
  const multifamilySchema = {
    ...completenessSchema,
    overview: {
      ...completenessSchema.overview,
      size: {
        ...completenessSchema.overview.size,
        subSteps: {
          gross: defaultSubStep(3),
          numberOfUnits: defaultSubStep(36),
        },
      },
    },
  } satisfies SchemaShape;

  const result = {
    propertyType: "multiFamily",
    completeness:
      generateFinalPathIds<typeof multifamilySchema>(multifamilySchema),
    onSite: generateFinalPathIds<typeof onSiteSchema>(onSiteSchema),
  } as const;

  return result;
};

export const getHotelSchema = () => {
  const hotelSchema = {
    ...completenessSchema,
    overview: {
      ...completenessSchema.overview,
      size: {
        ...completenessSchema.overview.size,
        subSteps: {
          gross: defaultSubStep(3),
          numberOfRooms: defaultSubStep(37),
        },
      },
    },
  } satisfies SchemaShape;

  const result = {
    propertyType: "hotel",
    completeness: generateFinalPathIds<typeof hotelSchema>(hotelSchema),
    onSite: generateFinalPathIds<typeof onSiteSchema>(onSiteSchema),
  } as const;

  return result;
};

export const getOfficeSchema = () => {
  const cSchema = {
    ...completenessSchema,
    overview: {
      ...completenessSchema.overview,
      size: {
        ...completenessSchema.overview.size,
        subSteps: {
          gross: defaultSubStep(3),
          rentable: defaultSubStep(4),
        },
      },
    },
  } satisfies SchemaShape;

  const result = {
    propertyType: "office",
    completeness: generateFinalPathIds<typeof cSchema>(cSchema),
    onSite: generateFinalPathIds<typeof onSiteSchema>(onSiteSchema),
  } as const;
  return result;
};

export type OnboardingGuideSchemaUnionType =
  | ReturnType<typeof getOfficeSchema>
  | ReturnType<typeof getMultifamilySchema>
  | ReturnType<typeof getHotelSchema>;
