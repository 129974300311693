import { useQuery, useMutation, useQueryClient } from "react-query";
import moment from "moment";
import { useCallback } from "react";
import { useFileBrowser } from "@src/global_functions/hooks";
import {
  downloadProjectAttachment,
  getProjectAttachmentPreviews,
  uploadProjectAttachment,
  downloadRewardReceipt,
  getRewardReceiptPreviews,
  uploadRewardReceipt,
} from "./projectsAttachmentsApi";

import { Skeleton, Text, Tooltip, Icon, Button } from "@src/straps/base";
import { ClickableDiv } from "@src/straps/utils/ClickableDiv";
import classNames from "classnames";
import FetchClients, {
  ClientOutput,
} from "@src/global_functions/backpackSdk/client";
import { bugsnagPostgrestErrorHandler } from "@src/global_functions/postgrestApi";
import * as R from "ramda";
const acceptedFileTypes = [".pdf", "application/pdf"];
type AttachmentType = "projects" | "rewards";

const serviceMap = {
  projects: {
    download: downloadProjectAttachment,
    getPreviews: getProjectAttachmentPreviews,
    getInformation: getBackpackProjectAttachmentInformation,
    upload: uploadProjectAttachment,
  },
  rewards: {
    download: downloadRewardReceipt,
    getPreviews: getRewardReceiptPreviews,
    getInformation: getRewardReceiptInformation,
    upload: uploadRewardReceipt,
  },
} as const;

const textMap = {
  projects: { title: "Documents" },
  rewards: { title: "Receipts*" },
} as const;

export const PREVIEW_BASE_KEY = "attachment-preview";
export const useAttachmentPreviewQuery = (
  project_id: number,
  type: AttachmentType,
  reward_id?: number
) => {
  const getPreviews = serviceMap[type].getPreviews;
  const getInformation = serviceMap[type].getInformation;

  const handleGetPreviews = useCallback(async () => {
    if (type === "projects") {
      return await getPreviews(project_id);
    }
    if (type === "rewards") {
      if (!reward_id) return {};
      return await getPreviews(reward_id);
    }
    return {};
  }, [getPreviews, project_id, reward_id, type]);

  const baseQueryKey = [PREVIEW_BASE_KEY, type, project_id];
  const queryKey =
    type === "rewards" ? [...baseQueryKey, reward_id] : baseQueryKey;
  return useQuery(queryKey, async () => {
    const images = await handleGetPreviews();
    // this will be updated to reward_id once a reward needs to exist pre-upload in a later release
    const names = await getInformation(project_id);
    const allFileNames = names.map((name) => name.filename);
    return names.map((name) => ({
      ...name,
      preview: images[name.id.toString()],
      hasDuplicate:
        name.version > 1 ||
        allFileNames.filter((n) => n === name.filename).length > 1,
    }));
  });
};

const useAttachmentUploadMutation = (
  projectId: number,
  type: AttachmentType
) => {
  const upload = serviceMap[type].upload;
  const queryClient = useQueryClient();
  const { mutateAsync: uploadFiles, isLoading: isUploading } = useMutation(
    (files: File[]) => upload(projectId, files[0]!),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([PREVIEW_BASE_KEY, type, projectId]),
    }
  );
  const [openFileBrowser] = useFileBrowser(
    acceptedFileTypes,
    useCallback(
      async (files) => {
        await uploadFiles(files);
      },
      [uploadFiles]
    )
  );
  return { openFileBrowser, isUploading };
};

export default function ProjectsAttachments({
  projectId,
  type = "projects",
  reward_id,
}: Readonly<{
  projectId: number;
  type?: AttachmentType;
  reward_id?: number;
}>) {
  const { data: previews, isLoading } = useAttachmentPreviewQuery(
    projectId,
    type,
    reward_id
  );
  const { openFileBrowser, isUploading } = useAttachmentUploadMutation(
    projectId,
    type
  );

  return (
    <>
      {previews?.length ? (
        <Text variant="sb_t-12-500" color="secondary">
          {`${textMap[type].title} (${(previews ?? []).length})`}
        </Text>
      ) : null}
      <div
        className={classNames(
          "flex items-center overflow-hidden transition-all duration-500",
          {
            "h-0 opacity-0": !isLoading,
            "h-5 opacity-100": isLoading,
          }
        )}
      >
        <Text
          variant="sb_t-12-500"
          color="tertiary"
        >{`${textMap[type].title} loading...`}</Text>
      </div>
      <div
        className={classNames("grid grid-flow-row grid-cols-2 gap-4", {
          "mb-4 mt-2": previews?.length || isUploading,
        })}
      >
        {isUploading &&
          (previews ?? []).map((_, i) => (
            <Skeleton key={i} className="h-36 w-full shadow" />
          ))}
        {isUploading && <Skeleton className="h-36 w-full shadow" />}
        {!isUploading && !isLoading && previews?.length
          ? previews.map(
              ({
                id,
                filename,
                version,
                has_preview,
                preview,
                hasDuplicate,
                uploaded_at,
              }) => (
                <ClickableDiv
                  className="h-36 w-full cursor-pointer bg-white shadow transition-all hover:shadow-md"
                  key={id}
                  onClick={() =>
                    serviceMap[type].download(id).then((link) => {
                      if (link) window.open(link, "_blank");
                    })
                  }
                >
                  <div className="h-[104px]">
                    {!has_preview || !preview ? (
                      <Text variant="bc_s-12-300" className="p-3">
                        No preview available
                      </Text>
                    ) : (
                      <img
                        className="block h-full w-full object-cover object-left-top"
                        alt="A preview of the attachment"
                        src={preview}
                      />
                    )}
                  </div>
                  <div className="flex h-10 items-center gap-1.5 border-t px-2">
                    <Tooltip
                      content={`Uploaded on ${moment(uploaded_at).format(
                        "MMM DD, YYYY"
                      )}`}
                      align="bottom"
                      fixed
                    >
                      <div className="flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-straps-tertiary/30 transition-all hover:bg-straps-primary hover:text-white [&_div]:hover:!text-white [&_span]:hover:!opacity-100 [&_svg]:hover:!text-white">
                        {hasDuplicate ? (
                          <Text
                            variant="sb_t-12-500"
                            className="font-semibold hover:text-white"
                            color="secondary"
                          >{`v${version}`}</Text>
                        ) : (
                          <Icon
                            name="data-submissions"
                            color="secondary"
                            className="opacity-70 transition-all"
                            size="small"
                          />
                        )}
                      </div>
                    </Tooltip>
                    <Text className="truncate" variant="sa_t-12-700">
                      {filename}
                    </Text>
                  </div>
                </ClickableDiv>
              )
            )
          : null}
      </div>
      <Button rounded onClick={openFileBrowser} icon="add-file" width="190px">
        {`Upload ${textMap[type].title}`}
      </Button>
    </>
  );
}

const attachmentCipher = (
  data:
    | Array<ClientOutput["projects"]["ProjectAttachment"]>
    | Array<ClientOutput["projects"]["RewardReceipt"]>
) => {
  return R.sortWith(
    [
      R.ascend(R.compose(R.toLower, R.prop("filename"))),
      R.ascend(R.prop("version")),
    ],
    data.map((attachment) => {
      const { project_id, uploaded_at, ...rest } = attachment;
      return {
        ...rest,
        uploaded_at: new Date(uploaded_at).toISOString(),
      };
    })
  );
};

export async function getBackpackProjectAttachmentInformation(
  project_id: number
) {
  const { data, error } = await FetchClients.projects.GET("/attachments", {
    params: {
      query: {
        project_id,
      },
    },
  });
  if (data) {
    return attachmentCipher(data);
  }
  // @todo throw an error and handle in component once projects-rewards flag is removed
  bugsnagPostgrestErrorHandler(error);
  return [];
}

async function getRewardReceiptInformation(project_id: number) {
  const { data, error } = await FetchClients.projects.GET(
    "/{project_id}/reward_receipts",
    {
      params: {
        path: {
          project_id,
        },
      },
    }
  );
  if (data) {
    return attachmentCipher(data);
  }
  // @todo throw an error and handle in component once projects-rewards flag is removed
  bugsnagPostgrestErrorHandler(error);
  return [];
}
