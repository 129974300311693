import env from "env-var";

// MYSTERY ??
export const VITE_TIMESERIES_JWT = env.get("VITE_TIMESERIES_JWT").asString();

export const isProd = import.meta.env.MODE === "production";
export const isDev = import.meta.env.MODE === "development";
export const isTest = import.meta.env.MODE === "test";

export const VITE_SITE = env
  .get("VITE_SITE")
  .required(!isTest)
  .asEnum(["valinor", "ip", "console", "admin"]);

export const isValinor = VITE_SITE === "valinor";
export const isIP = VITE_SITE === "ip";
export const isBackpack = VITE_SITE === "console";

export const LOGIN_URL = (
  isBackpack
    ? env.get("VITE_BACKPACK_LOGIN_URL")
    : env.get("VITE_BRACTLET_LOGIN_URL")
)
  .required(isProd)
  .asString();

export const VITE_LEDGER_BASE_URL = env
  .get("VITE_LEDGER_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_UPLOAD_BASE_URL = env
  .get("VITE_UPLOAD_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_REPORTER_BASE_URL = env
  .get("VITE_REPORTER_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_SITE_SERVICE_BASE_URL = env
  .get("VITE_SITE_SERVICE_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_TENANTS_AND_SPACES_BASE_URL = env
  .get("VITE_TENANTS_AND_SPACES_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_UTILITIES_BASE_URL = env
  .get("VITE_UTILITIES_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_PORTABLE_DOCUMENT_FORMAT_BASE_URL = env
  .get("VITE_PORTABLE_DOCUMENT_FORMAT_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_TIMESCALE_QUERY_API_BASE_URL = env
  .get("VITE_TIMESCALE_QUERY_API_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_VALINOR_URL = env
  .get("VITE_VALINOR_URL")
  .required(isProd)
  .asString();

export const VITE_IP_URL = env.get("VITE_IP_URL").required(!isTest).asString();

export const VITE_ADMIN_URL = env
  .get("VITE_ADMIN_URL")
  .required(!isTest)
  .asString();

export const VITE_BACKPACK_URL = env
  .get("VITE_BACKPACK_URL")
  .required(!isTest)
  .asString();

export const VITE_ARCADIA_BASE_URL = env
  .get("VITE_ARCADIA_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_ACTIVITY_LOGS_BASE_URL = env
  .get("VITE_ACTIVITY_LOGS_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_MARKETPLACE_BASE_URL = env
  .get("VITE_MARKETPLACE_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_LIVESESSION_ID = env
  .get("VITE_LIVESESSION_ID")
  .required(isProd)
  .asString();

export const intendedOrigin = env
  .get(
    `VITE_${(VITE_SITE === "console"
      ? "BACKPACK"
      : VITE_SITE || ""
    ).toUpperCase()}_URL`
  )
  .asString();

// ARC / LEED
export const VITE_ARC_REFRESH_TOKEN = env
  .get("VITE_ARC_REFRESH_TOKEN")
  // .required(isDev)
  .asString();

export const VITE_ARC_STATE_STRING = env
  .get("VITE_ARC_STATE_STRING")
  // .required(isDev)
  .asString();

// GRESB
export const VITE_GRESB_TOKEN = env
  .get("VITE_ARC_STATE_STRING")
  // .required(isDev)
  .asString();

export const VITE_BRACTLET_ENERGY_STAR_ID = env
  .get("VITE_BRACTLET_ENERGY_STAR_ID")
  .required(!isTest)
  .asInt();

// DEVELOPMENT

export const VITE_ENABLE_UNSAFE_REQUESTS = env
  .get("VITE_ENABLE_UNSAFE_REQUESTS")
  .asBoolStrict();

export const VITE_LD_CLIENT_ID = env
  .get("VITE_LD_CLIENT_ID")
  .required(!isTest)
  .asString();

export const PENDO_KEY = (
  isBackpack
    ? env.get("VITE_BACKPACK_PENDO_KEY")
    : env.get("VITE_BRACTLET_PENDO_KEY")
)
  .required(isProd)
  .asString();

export const VITE_MOCK_SERVICE_WORKER = env
  .get("VITE_MOCK_SERVICE_WORKER")
  .asBoolStrict();

export const VITE_APP_VERSION = env.get("VITE_APP_VERSION").asString();

export const VITE_ENABLE_STAGING_LOGIN_MODAL = env
  .get("VITE_ENABLE_STAGING_LOGIN_MODAL")
  .asBoolStrict();

export const VITE_DISABLE_SUPER_ADMIN_IN_CLIENT = env
  .get("VITE_DISABLE_SUPER_ADMIN_IN_CLIENT")
  .asBoolStrict();
