import classNames from "classnames";
import * as React from "react";
import { useOnboardingGuideContext } from ".";
import { TooltipWrapper } from "../../../global_components";
import Icons from "../../../icons";
import { ClickableDiv } from "@src/straps/utils/ClickableDiv";
import Icon from "@src/straps/base/icons/Icon/Icon";
import {
  OnboardingStatusEnum,
  OnboardingStepType,
  OnboardingSubStep,
} from "./completenessSchema";
import { isOptedOutStepOmittedFromCount } from "./utils";

type OnboardingGuideStepProps = Readonly<{
  step: OnboardingStepType | OnboardingSubStep;
  text: string;
  errorText?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}>;
export default function OnboardingGuideStep(props: OnboardingGuideStepProps) {
  const { step, text, errorText, onClick = () => {} } = props;

  const { activeStep } = useOnboardingGuideContext();

  const error = step.status === OnboardingStatusEnum.Done_NA;
  if (step.hidden) {
    return null;
  }

  const informational =
    step.stepType !== "subStep" ? step.informational : false;
  const readOnly = step.readOnly;
  const notClickableInMenuWhenComplete =
    step.stepType !== "subStep" ? step.notClickableInMenuWhenComplete : false;

  return (
    <ClickableDiv
      data-cy="onboarding-step"
      data-testid="onboarding-step"
      className={classNames("flex items-center px-5 py-1 text-xs", {
        "text-charcoal700": step.complete || informational,
        "text-straps-accent-1": !step.complete && !informational,
        "text-straps-negative": error,
        "bg-blue-100": activeStep?.includes(step.pathId), // checking includes instead of === because of sub steps (we want to highlight the parent step in those cases)
        "cursor-pointer":
          !readOnly &&
          (!notClickableInMenuWhenComplete ||
            (notClickableInMenuWhenComplete &&
              step.status === OnboardingStatusEnum.Open)),
      })}
      onClick={onClick}
    >
      <span className="mr-auto">{error ? errorText : text}</span>
      <TooltipWrapper
        renderOnTarget
        align="right"
        tooltip={
          <div className="relative rounded-sm p-2 text-xs font-semibold">
            {informational ? "Informational" : tooltipText[step.status]}
            <div className="absolute -left-1 bottom-1/2 h-2 w-2 translate-y-1/2 rotate-45 transform bg-white" />
          </div>
        }
      >
        <span className="inline-flex items-center">{getIcon(step)}</span>
      </TooltipWrapper>
    </ClickableDiv>
  );
}

function getIcon(step: OnboardingStepType | OnboardingSubStep) {
  if (step.status === OnboardingStatusEnum.Done_NA)
    return <Icons icon="Ban" className="color--red h-3 w-3" />;

  if (isOptedOutStepOmittedFromCount(step))
    return <Icon name="opt-out" size="small" color="secondary" />;

  if (step.countable && step.complete)
    return (
      <Icons
        icon="Check"
        className="color--charcoal h-2.5 w-2.5"
        aria-label="step completed"
      />
    );

  if (step.status === OnboardingStatusEnum.Processing)
    return (
      <Icons
        icon="BackpackLogoNegative"
        className="color--blue-gray h-4 w-3"
        aria-label="step processing"
      />
    );

  const informational =
    step.stepType !== "subStep" ? step.informational : false;
  const readOnly = step.readOnly;

  if (informational) {
    return <Icon name="info" size="small" color="secondary" />;
  }

  if (step.status === OnboardingStatusEnum.Open && !readOnly)
    return (
      <Icon
        name="caret-right"
        color="accent-1"
        size="small"
        className="!w-1.5"
        aria-label="jump to step"
      />
    );

  return null;
}

const tooltipText = {
  [OnboardingStatusEnum.Done]: "Completed",
  [OnboardingStatusEnum.Processing]: "Processing",
  [OnboardingStatusEnum.Open]: "Not completed",
  [OnboardingStatusEnum.Done_NA]: "Not Available",
  [OnboardingStatusEnum.OptOut]: "Opted out",
  [OnboardingStatusEnum.N_A]: "Not applicable",
} as const;
