import React, { forwardRef, Ref, useEffect, useMemo } from "react";
import DayPicker, {
  DayPickerProps,
  Modifier,
  NavbarElementProps,
} from "react-day-picker";
import "react-day-picker/lib/style.css";
import classNames from "classnames";

import moment from "moment";
import Text from "../../type/Text/Text";
import Input, { InputStatus } from "../Input/Input";
import { RoundedVariantProps } from "../Input/inputVariants";

import DropdownWrapper from "../../../utils/DropdownWrapper/DropdownWrapper";
import useSubscription from "../../../../global_functions/hooks/useSubscription";
import Icon from "../../icons/Icon/Icon";
import { ClickableDiv } from "../../../utils/ClickableDiv";

// @todo - add next/prev year functionality after upgrading to react-day-picker v8
// https://react-day-picker.js.org/guides/upgrading

type DatePickerProps = {
  value?: string;
  label?: string | undefined;
  format?: string | string[];
  className?: string | null;
  disabledDates?: Modifier[];
  style?: Record<any, any>;
  handleDateChange?: (arg1: string) => void;
  defaultMonth?: Date;
  dataTestId?: string;
  enableFutureDates?: boolean;

  // input props

  status?: InputStatus;
  supportText?: string;
  validation?: string;
  extendContainerRefs?: React.RefObject<HTMLElement>[];
  extraDropdownRef?: React.RefObject<HTMLDivElement>;
  backgroundColor?: RoundedVariantProps["backgroundColor"];
  placeholder?: string;
};

// type DatePickerVariants = "single" | "range";
/**
 * For use in console, may encounter timezone offset issue if used in IP/Valinor
 * due to setting moment default timezone to building's timezone in `/src/site__/SiteRouter.tsx`
 * @param format provide date format if not "YYYY-MM-DD or "MMM D, YYYY"
 */
export default function DatePickerInput({
  name,
  disabled,
  value,
  label,
  status,
  supportText,
  validation,
  extendContainerRefs,
  extraDropdownRef,
  backgroundColor,
  dataTestId = "date-picker-input",
  placeholder,
  ...datePickerProps
}: Omit<DatePickerProps, "onCancel"> & {
  name: string;
  disabled?: boolean;
}) {
  const [closeSubscription, closeEvent] = useSubscription();

  useEffect(() => {
    // close dropdown on date selection.
    // todo - add additional condition for multiselect
    closeEvent();
  }, [value, closeEvent]);
  return (
    <DropdownWrapper
      closeSubscription={closeSubscription}
      extraRefs={extendContainerRefs}
      extraDropdownRef={extraDropdownRef}
      as={
        <Input
          dataTestId={dataTestId}
          name={name}
          label={label}
          disabled={disabled}
          placeholder={placeholder}
          value={value ?? ""}
          readOnly={true}
          variant="rounded"
          iconSecondary="date-picker"
          className="cursor-pointer"
          status={status}
          supportText={supportText}
          validation={validation}
          backgroundColor={backgroundColor}
        />
      }
    >
      <DatePicker value={value} {...datePickerProps} />
    </DropdownWrapper>
  );
}

const DayPickerNavBar = (props: NavbarElementProps) => {
  return (
    <div className="flex items-center justify-between bg-straps-primary-hover">
      <ClickableDiv
        onClick={() => props.onPreviousClick()}
        className="group flex h-10 cursor-pointer items-center px-2"
      >
        <Icon name="caret-left" className="group-hover:text-white" />
      </ClickableDiv>

      <Text variant="sb_t-14-500" color="white">
        {moment(props.month).format("MMMM YYYY")}
      </Text>

      <ClickableDiv
        className="group flex h-10 cursor-pointer items-center px-2"
        onClick={() => props.onNextClick()}
      >
        <Icon name="caret-right" className="group-hover:text-white" />
      </ClickableDiv>
    </div>
  );
};

export const DatePicker = forwardRef(function DatePicker(
  {
    value,
    format = ["YYYY-MM-DD", "MMM D, YYYY"],
    className,
    handleDateChange,
    disabledDates,
    defaultMonth,
    enableFutureDates,
    ...props
  }: DatePickerProps,
  ref: Ref<HTMLDivElement>
) {
  const disabledDays = useMemo(() => {
    const result: Modifier[] = enableFutureDates
      ? []
      : [{ after: moment().toDate() }];

    if (disabledDates) result.push(...disabledDates);
    return result;
  }, [disabledDates, enableFutureDates]);
  // moment() parses dates to local timezone unless offset is included
  // https://momentjs.com/guides/#/parsing/local-utc-zone/
  const dayPickerProps = {
    selectedDays: value ? moment(value, format).toDate() : undefined,
    modifiers: {
      today: value ? moment(value, format).toDate() : undefined,
      outside: undefined,
    },
    numberOfMonths: 1,
    todayButton: "Today",
    navbarElement: DayPickerNavBar,
    showOutsideDays: true,
    enableOutsideDaysClick: false,
    disabledDays,
    month:
      (value ? moment(value, format).toDate() : defaultMonth) ??
      moment().toDate(),
    captionElement: () => null,
    onDayClick: (day, modifiers, e) => {
      // day is a Date in local timezone
      const clickedDate = moment(day).format("YYYY-MM-DD");
      if (modifiers && !modifiers.disabled) {
        handleDateChange?.(clickedDate);
      }
    },
    onTodayButtonClick: () => {
      handleDateChange?.(moment().format("YYYY-MM-DD"));
    },
  } satisfies DayPickerProps;

  return (
    <div
      data-testid="date-picker-container"
      className={classNames(
        className,
        // todo - migrate this to TW after upgrade (using modifiersClassnames prop)
        "date-selection--container single-date-selection--container"
      )}
      ref={ref}
      {...props}
    >
      <DayPicker {...dayPickerProps} />
    </div>
  );
});
