import { useEffect, useState, useCallback, forwardRef } from "react";
import DatePickerInput from "@src/straps/base/inputs/DatePicker/DatePicker";
import { useCurrentUser } from "@src/context/UserContext";
import backpackSdk from "@src/global_functions/backpackSdk/sdk";
import { useDetailedSiteSummaryQuery } from "@src/global_functions/sites/sitesQueries";
import { Skeleton, Switch, Text, Tooltip, Icon } from "@src/straps/base";
import moment from "moment";
import { useSiteIdParam } from "@src/context/SitesContext";
import classNames from "classnames";

const formatDate = (date: string) =>
  moment(date, "YYYY-MM-DD").format("MMM D, YYYY");

const OnboardingGuideCompletedDate = forwardRef<HTMLDivElement>(
  function OnboardingGuideCompletedDate(_, ref) {
    const siteId = useSiteIdParam();
    const [onboardingDate, setOnboardingDate] = useState<string>();
    const [isCompleted, setIsCompleted] = useState(false);
    const [erroredValue, setErroredValue] = useState<string | null>();
    const { usePatch } = backpackSdk.postgrest.sites.mutations;
    const { mutate: patchSite, isError: isPatchError } = usePatch(siteId);
    const { data: site, isLoading: siteLoading } = useDetailedSiteSummaryQuery({
      siteId: siteId,
    });

    const handleDateChange = useCallback(
      (date: string) => {
        setOnboardingDate(formatDate(date));
        patchSite(
          { onboardingCompletedAt: date },
          {
            onError: (__, { onboardingCompletedAt }) =>
              setErroredValue(onboardingCompletedAt),
          }
        );
      },
      [patchSite]
    );

    const handleCompletedChange = useCallback(
      (checked: boolean) => {
        if (!checked) {
          patchSite(
            { onboardingCompletedAt: null },
            {
              onError: (__, { onboardingCompletedAt }) =>
                setErroredValue(onboardingCompletedAt),
            }
          );
          setIsCompleted(false);
          setOnboardingDate(undefined);
        }
        if (checked) {
          setIsCompleted(true);
        }
      },
      [patchSite]
    );

    const { onboardingCompletedAt: completedAt } = site ?? {};

    useEffect(() => {
      if (completedAt) {
        setOnboardingDate(formatDate(completedAt));
        setIsCompleted(true);
      } else {
        setOnboardingDate(undefined);
        setIsCompleted(false);
      }
    }, [completedAt]);

    const { superAdmin } = useCurrentUser();

    useEffect(() => {
      if (isPatchError) {
        setOnboardingDate(completedAt ? formatDate(completedAt) : undefined);
      }
      if (erroredValue === null) {
        setIsCompleted(!!completedAt);
      }
    }, [completedAt, erroredValue, isPatchError]);

    const isDeleteError = isPatchError && erroredValue === null;
    const isUpdateError = isPatchError && erroredValue !== null;

    if (siteLoading) {
      return <Skeleton className="mx-5 mb-5 h-3.5 w-[202px] pt-0.5" />;
    }

    if (!superAdmin && !onboardingDate) {
      return null;
    }

    return superAdmin ? (
      <div
        className="flex w-full flex-col justify-between gap-2 pl-5 pr-4 pt-0.5 "
        data-testid="onboarding-date-super-admin-container"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Text variant="sb_t-14-500">
              {isCompleted ? "Completed" : "Incomplete"}
            </Text>
            {isDeleteError && (
              <Tooltip content="Failed to delete" fixed align="topRight">
                <Icon
                  name="warning"
                  color="negative"
                  className="ml-2"
                  size="medium"
                />
              </Tooltip>
            )}
          </div>
          <Switch
            onChange={(e) => handleCompletedChange(e.target.checked)}
            checked={isCompleted}
            size="small"
          />
        </div>
        <div
          data-testid="onboarding-date-date-input-container"
          className={classNames("overflow-hidden pr-1 transition-all", {
            "h-0 opacity-0": !isCompleted,
            "h-[54px] opacity-100": isCompleted,
          })}
        >
          <div className="flex items-center">
            <DatePickerInput
              handleDateChange={handleDateChange}
              value={onboardingDate}
              status={isUpdateError ? "negative" : undefined}
              name="onboardingDatePicker"
              backgroundColor="body"
              extraDropdownRef={
                ref && typeof ref !== "function" ? ref : undefined
              }
            />
            {isUpdateError && (
              <Tooltip content="Failed to save" fixed align="topRight">
                <Icon
                  name="warning"
                  color="negative"
                  className="ml-2"
                  size="medium"
                />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    ) : (
      <Text
        variant="sb_t-10-500"
        className="px-5 pb-5"
        data-testid="onboarding-date-read-only"
      >{`Completed on ${onboardingDate}`}</Text>
    );
  }
);

export default OnboardingGuideCompletedDate;
