import { useMutation } from "react-query";
import FetchClients, { ClientOutput } from "../client";
import { BackpackServiceError, isBackpackResponseErrorBody } from "../errors";

const document = {
  mutations: {
    usePostBulkShare,
  },
} as const;

export default document;

export const DOCUMENT_KEY = "documents/document";

export type BulkShareRequest = ClientOutput["d3"]["BulkShareReq"];

export const EMAIL_NOT_ON_ACCOUNT_ERROR_CODE = 1001;
function usePostBulkShare() {
  return useMutation({
    mutationFn: async (req: BulkShareRequest) => {
      const { error, response } = await FetchClients.d3.POST(
        "/design/bulkshare",
        {
          body: req,
        }
      );
      if (response.ok) return;
      if (isBackpackResponseErrorBody(error)) {
        throw new BackpackServiceError(error);
      }
      throw new Error("Unknown error");
    },
  });
}
