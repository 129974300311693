import Cookies from "js-cookie";
import { camelCase } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { VITE_UTILITIES_BASE_URL } from "../../../env";
import { Utility } from "../../../types";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "../../postgrestApi";
import request from "../../request";
import { throwBackpackSuperagentResponseError } from "../errors";
import { UTILITY_BILL_CONSUMPTION_KEY } from "./consumption";
import { ClientOutput } from "../client";
import { CamelCasedProperties } from "type-fest";

// @todo can use generated types with no modifications when https://bractlet.atlassian.net/browse/INNO-765 is done
export type UtilityBillBase = Omit<
  CamelCasedProperties<ClientOutput["utilities"]["Bill"]>,
  "utilityType" | "taxesAndOther" | "totalCost"
> & {
  utilityType: Utility;
  taxesAndOther?: number | null;
  totalCost?: number | null;
};

export type UtilityBillAggregatedConsumption = Omit<
  CamelCasedProperties<ClientOutput["utilities"]["BillAggregatedConsumption"]>,
  | "utilityType"
  | "taxesAndOther"
  | "totalCost"
  | "totalConsumptionCost"
  | "totalConsumption"
  | "blendedRate"
> & {
  utilityType: Utility;
  taxesAndOther?: number | null;
  totalCost?: number | null;
  totalConsumptionCost?: number | null;
  totalConsumption: number;
  blendedRate: number;
};

export type UnitParam = ClientOutput["utilities"]["UnitConversion"];

const bill = {
  useQueryAllAggregatedConsumption,
  useQueryAggregatedConsumption,
  mutations: {
    usePost,
    usePut,
    useDelete,
  },
} as const;

export default bill;

// GET

const getAllUtilityBillsAggregatedConsumption = (
  siteId: string | number,
  unit: UnitParam
): Promise<UtilityBillAggregatedConsumption[]> =>
  request
    .get(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/bills`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .query({ unit })
    .then(({ body }) =>
      recursiveCamelCaseCipher(body).map(
        (res: UtilityBillAggregatedConsumption) => ({
          ...res,
          utilityType: camelCase(res.utilityType),
        })
      )
    );

function useQueryAllAggregatedConsumption(
  siteId: number,
  options: { unit: UnitParam } = { unit: "default" }
) {
  const { unit } = options;
  return useQuery(
    [UTILITY_BILL_CONSUMPTION_KEY, siteId, "aggregated", unit],
    () => getAllUtilityBillsAggregatedConsumption(siteId, unit),
    {
      select: (items): UtilityBillAggregatedConsumption[] =>
        items.map((item) =>
          item.utilityType === "wasteWater"
            ? { ...item, utilityType: "water" }
            : item
        ),
    }
  );
}

const getUtilityBillAggregatedConsumption = (
  siteId: string | number,
  billId: number,
  unit: UnitParam
): Promise<UtilityBillAggregatedConsumption> =>
  request
    .get(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/bills/${billId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .query({ unit })
    .then(({ body }) => ({
      ...recursiveCamelCaseCipher(body),
      utilityType: camelCase(body.utility_type),
    }));

function useQueryAggregatedConsumption(
  siteId: number,
  billId: number,
  options: { unit?: UnitParam; enabled?: boolean } = {}
) {
  const { unit = "default", enabled } = options;
  return useQuery(
    [UTILITY_BILL_CONSUMPTION_KEY, siteId, billId, "aggregated", unit],
    () => getUtilityBillAggregatedConsumption(siteId, billId, unit),
    {
      select: (item): UtilityBillAggregatedConsumption =>
        item.utilityType === "wasteWater"
          ? { ...item, utilityType: "water" }
          : item,
      enabled,
    }
  );
}

// POST

export type PostUtilityBillRequest = Partial<
  Omit<UtilityBillAggregatedConsumption, "id">
>;

export type PostUtilityBillResponse =
  ClientOutput["utilities"]["CreateBillResponse"];

const generateErrorMessage = (
  { accountNumber, providerId }: PostUtilityBillRequest,
  siteId: number
) => {
  if (accountNumber && providerId) {
    return `Unable to create bill, make sure utility account "${accountNumber}" and provider ID "${providerId}" are set up for site ${siteId}.`;
  }
  if (accountNumber) {
    return `Unable to create bill, make sure utility account "${accountNumber}" is set up for site ${siteId}.`;
  }
  if (providerId) {
    return `Unable to create bill, make sure provider ID "${providerId}" is set up for site ${siteId}.`;
  }
  return "Unable to create bill.";
};

const postUtilityBill = (
  siteId: number,
  req: PostUtilityBillRequest
): Promise<PostUtilityBillResponse> =>
  request
    .post(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/bills`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("Content-Type", "application/json")
    .send(recursiveSnakeCaseCipher(req, { convertUtilityValues: true }))
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(`Unable to create bill for site: ${siteId}`);
      }
      return recursiveCamelCaseCipher(res.body);
    })
    .catch((e) => {
      if (e.response?.statusCode === 404) {
        throw new Error(generateErrorMessage(req, siteId));
      }
      throwBackpackSuperagentResponseError(e);
    });

function usePost(siteId: number) {
  return useMutation({
    mutationFn: (req: PostUtilityBillRequest) => postUtilityBill(siteId, req),
  });
}

// PUT

export type PutUtilityBillRequest = Partial<UtilityBillBase> & { id: number };

export type PutUtilityBillResponse = PostUtilityBillResponse;

const putUtilityBill = (
  siteId: number,
  req: PutUtilityBillRequest
): Promise<PutUtilityBillResponse> =>
  request
    .put(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/bills/${req.id}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("Content-Type", "application/json")
    .send(recursiveSnakeCaseCipher(req, { convertUtilityValues: true }))
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(`Unable to update bill for site: ${siteId}`);
      }
      return recursiveCamelCaseCipher(res.body);
    })
    .catch(throwBackpackSuperagentResponseError);

function usePut(siteId: number) {
  const queryClient = useQueryClient();
  const queryKey = [UTILITY_BILL_CONSUMPTION_KEY, siteId];

  return useMutation({
    mutationFn: (req: PutUtilityBillRequest) => putUtilityBill(siteId, req),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey,
      });
    },
  });
}

// DELETE

const deleteUtilityBill = (siteId: number, billId: number): Promise<void> =>
  request
    .delete(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/bills/${billId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .then(({ status }) => {
      if (status !== 200 && status !== 204) {
        // unsure which status yet
        // TODO Backend should return error response status and message for these cases
        throw new Error("Delete bill failed.");
      }
    })
    .catch(throwBackpackSuperagentResponseError);

function useDelete(siteId: number, billId: number) {
  const queryClient = useQueryClient();
  const queryKey = [UTILITY_BILL_CONSUMPTION_KEY, siteId];
  return useMutation({
    mutationFn: () => deleteUtilityBill(siteId, billId),
    onMutate: async () => {
      // cancel queries
      await queryClient.cancelQueries({
        queryKey,
      });
    },
    onSuccess: async () => {
      // we just need to remove the aggregated consumption record
      // synchronously as this removes the single consumption records
      queryClient.setQueryData(
        [UTILITY_BILL_CONSUMPTION_KEY, siteId, "aggregated"],
        (old?: UtilityBillAggregatedConsumption[]) =>
          (old ?? []).filter((agg) => agg.id !== billId)
      );

      await queryClient.invalidateQueries({
        queryKey,
      });
    },
  });
}
