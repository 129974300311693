import classnames from "classnames";
import Cookies from "js-cookie";
import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";
import * as React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { bugsnagClient } from "./bugsnag";
import SitesContext, { SiteContext } from "./context/SitesContext";
import UserContext from "./context/UserContext";
import DebugBanner from "./DebugBanner";
import {
  isBackpack,
  isDev,
  isIP,
  LOGIN_URL,
  VITE_ARC_REFRESH_TOKEN,
  VITE_ARC_STATE_STRING,
  VITE_BACKPACK_URL,
  VITE_ENABLE_STAGING_LOGIN_MODAL,
  VITE_DISABLE_SUPER_ADMIN_IN_CLIENT,
  VITE_GRESB_TOKEN,
  VITE_IP_URL,
  VITE_LD_CLIENT_ID,
  VITE_MOCK_SERVICE_WORKER,
  VITE_SITE,
} from "./env";
import { Header, Loader } from "./global_components";
import { queryCurrentUser } from "./global_functions/postgrestApi";
import { isInExpectedEnv } from "./global_functions/request";
import {
  handleGetSites,
  handleRefreshSite,
  SITE_SUMMARY_FIELDS,
} from "./global_functions/sites/sitesHelpers";
import useAsync, { useAsyncLazy } from "./global_functions/useAsync";
import LiveSession from "./livesession";
import PortalRoot from "./PortalRoot";
import AppNavigation from "./site__/AppNavigation";
import CookieConsentBanner from "./site__/CookieConsentBanner";
import StagingLoginModal from "./site__/StagingLoginModal";
import { BackpackTelemetryManager } from "./telemetry";
import type { SummaryOverview } from "./types";

const ValinorRouter = React.lazy(() => import("./ValinorRouter"));
const AdminRouter = React.lazy(() => import("./AdminRouter"));
const IPRouter = React.lazy(() => import("./IPRouter"));
const BackpackConsoleRouter = React.lazy(
  () => import("./backpack-console/Router")
);

// https://github.com/microsoft/TypeScript/issues/48949
const win: Window = window;

const redirectToLogin = () => {
  win.location = LOGIN_URL;
  return win.location;
};

const validateBackpackEmailDomain = (email: string) =>
  email.endsWith("@backpacknetworks.com");

const testArcUserApiQueries = false; // set to true to test queries in arcUserApi.js in dev for site 15

const bractletRouterMap = new Map<typeof VITE_SITE, any>([
  ["valinor", <ValinorRouter key="valinor" />],
  ["ip", <IPRouter key="ip" />],
  ["admin", <AdminRouter key="admin" />],
]);

if (Cookies.get("jwt") == null) {
  if (!VITE_ENABLE_STAGING_LOGIN_MODAL) {
    redirectToLogin();
  }
}

const BractletContent: React.FunctionComponent<{
  readonly currentUserExists: boolean;
}> = ({ currentUserExists }) => {
  React.useEffect(() => {
    // Set straps colors to Bractlet theme
    document.body.classList.add("legacy-bractlet-colors");
  }, []);

  return (
    <div
      className={classnames("app-wrapper", {
        margin: ["ip", "valinor", "admin"].includes(VITE_SITE),
      })}
    >
      <AppNavigation />
      <Route
        path={[
          "/site/:siteId?/:page?",
          "/building-data-model/:page?",
          "/users-and-accounts/:page?",
          "/sensors-and-integrations/:page?",
          "/account/:accountId/:page?",
          "/:page?",
        ]}
        component={Header}
      />
      <main
        id="dropdown-root"
        className="main-content-container content-scroll-container"
      >
        {/* If currentUser exists then we can render main apps */}
        {!currentUserExists && <Loader size={80} />}
        <React.Suspense fallback={<Loader size={80} />}>
          {bractletRouterMap.get(VITE_SITE)}
        </React.Suspense>
        {isIP && <CookieConsentBanner />}
      </main>
    </div>
  );
};

function AppWrapper() {
  const [stagingLoginModalOpen, setStagingLoginModalOpen] =
    React.useState(false);
  const ldClient = useLDClient();

  const [currentUser, refreshCurrentUser] = useAsync(async () => {
    if (!ldClient) {
      return;
    }

    if (VITE_MOCK_SERVICE_WORKER) {
      await import("./__mocks__/msw/browser").then(({ worker }) => {
        worker.start({
          onUnhandledRequest: "bypass",
        });
      });
    }

    const currUser = await queryCurrentUser();

    if (!currUser) {
      if (VITE_ENABLE_STAGING_LOGIN_MODAL) {
        setStagingLoginModalOpen(true);
      } else {
        redirectToLogin();
      }
    } else {
      LiveSession.identifyUser({
        email: currUser.email,
        name: `${currUser.firstName ?? ""} ${currUser.lastName ?? ""}`,
        accountId: `${currUser.accountId ?? ""}`,
        userId: currUser.id,
      });

      ldClient.identify({
        kind: "user",
        key: currUser.id.toString(),
        firstName: currUser.firstName ?? "N/A",
        lastName: currUser.lastName ?? "N/A",
        email: currUser.email,
        avatar: currUser.imageURL ?? "N/A",
        accountId: currUser.accountId ?? "N/A",
        superAdmin: currUser.superAdmin,
        permissions: currUser.userPermissions,
        userRole: currUser.userRole ?? "N/A",
        title: currUser.title ?? "N/A",
      });

      bugsnagClient?.setUser(
        String(currUser.id),
        currUser.email,
        `${currUser.firstName ?? ""} ${currUser.lastName ?? ""}`
      );

      bugsnagClient?.addMetadata("user", {
        accountId: currUser.accountId,
        superAdmin: currUser.superAdmin,
        permissions: currUser.userPermissions,
        userRole: currUser.userRole ?? "N/A",
        title: currUser.title ?? "N/A",
      });

      bugsnagClient?.addFeatureFlags(
        Object.entries(ldClient.allFlags()).map(([name, variant]) => ({
          name,
          variant: variant !== null ? String(variant) : null,
        }))
      );

      // ensure non-admins (customers) get redirected to the customer-facing
      // site:
      const customerURL = isBackpack ? VITE_BACKPACK_URL : VITE_IP_URL;
      if (
        !currUser.superAdmin &&
        !validateBackpackEmailDomain(currUser.email) &&
        win.location.origin !== customerURL &&
        isInExpectedEnv
      ) {
        win.location = customerURL;
      }

      const devArcAuth =
        isDev && testArcUserApiQueries
          ? {
              arcRefreshToken: VITE_ARC_REFRESH_TOKEN,
              arcStateString: VITE_ARC_STATE_STRING,
            }
          : {};

      const devGresbAuth =
        isDev && VITE_GRESB_TOKEN ? { gresbOauthToken: VITE_GRESB_TOKEN } : {};

      const devDisableSuperAdmin =
        isDev && currUser.superAdmin && VITE_DISABLE_SUPER_ADMIN_IN_CLIENT
          ? { superAdmin: false }
          : {};

      return isDev
        ? {
            ...currUser,
            ...devArcAuth,
            ...devGresbAuth,
            ...devDisableSuperAdmin,
          }
        : currUser;
    }
  }, [ldClient]);

  const userContextValue = React.useMemo(
    () => ({
      currentUser: currentUser.data,
      refreshCurrentUser,
    }),
    [currentUser, refreshCurrentUser]
  );

  React.useEffect(() => {
    refreshCurrentUser();
  }, [refreshCurrentUser]);
  const currentUserExists = Boolean(currentUser);

  return (
    <Router>
      <UserContext.Provider value={userContextValue}>
        <SitesContextProvider
          isSuperAdmin={userContextValue.currentUser?.superAdmin}
        >
          <BackpackTelemetryManager />
          <PortalRoot />
          <DebugBanner />
          {stagingLoginModalOpen && <StagingLoginModal />}

          {isBackpack ? (
            <React.Suspense fallback={<Loader size={80} />}>
              <BackpackConsoleRouter currentUserExists={currentUserExists} />
            </React.Suspense>
          ) : (
            <BractletContent currentUserExists={currentUserExists} />
          )}
        </SitesContextProvider>
      </UserContext.Provider>
    </Router>
  );
}

export function SitesContextProvider({
  children,
  isSuperAdmin,
}: {
  isSuperAdmin?: boolean;
  children: React.ReactNode;
}) {
  const [sitesState] = useAsyncLazy<Array<SummaryOverview>>(
    () => handleGetSites(isSuperAdmin, SITE_SUMMARY_FIELDS),
    [isSuperAdmin]
  );

  const setSites = sitesState.setData;
  const [refreshSiteState, refreshSite] = useAsync(
    async (siteId: string | number) =>
      handleRefreshSite(siteId, setSites, isSuperAdmin, SITE_SUMMARY_FIELDS),
    [setSites, isSuperAdmin]
  );

  const siteContextValue: SiteContext = React.useMemo(
    () => ({
      activeSites:
        sitesState.data?.filter(({ status }) => status !== "inactive") || [],
      allSites: sitesState.data || [],
      refreshSite,
      sitesState,
      refreshSiteState,
      loading: sitesState.loading,
    }),
    [sitesState, refreshSite, refreshSiteState]
  );

  return (
    <SitesContext.Provider value={siteContextValue}>
      {children}
    </SitesContext.Provider>
  );
}

export default withLDProvider({
  clientSideID: VITE_LD_CLIENT_ID,
  // initialize app with anonymous user context to prevent blowing through our LD Monthly Active User limit
  context: { kind: "user", key: "uninitialized-user", anonymous: true },
})(AppWrapper as React.ComponentType<{}>);
