import { useQueries as useReactQueries } from "react-query";

import FetchClients from "../client";

const accounts = {
  useQueries,
} as const;

export default accounts;

export const ARCADIA_ACCOUNTS_KEY = "arcadia/accounts";

async function getAccount(arc_account_id: string) {
  const { data, error } = await FetchClients.arcadia.GET(
    "/accounts/{arc_account_id}",
    {
      params: {
        path: {
          arc_account_id,
        },
      },
    }
  );
  if (data) return data;
  throw new Error(error);
}

function useQueries(arc_account_ids: string[] = []) {
  return useReactQueries(
    arc_account_ids.map((arc_account_id) => ({
      queryKey: [ARCADIA_ACCOUNTS_KEY, arc_account_id],
      queryFn: () => getAccount(arc_account_id),
      retry: false,
    }))
  );
}
