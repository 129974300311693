import React, { useEffect, useMemo, useRef } from "react";
import { createPortal } from "react-dom";

import { Transition } from "react-transition-group";
import classNames from "classnames";
import { TransitionStatus } from "react-transition-group/Transition";
import { SubscribeFn } from "../../../global_functions/hooks/useSubscription";
import CollapsibleContainer from "../CollapsibleContainer/CollapsibleContainer";
import { PORTAL_ROOT_ID } from "@src/PortalRoot";
import { useMergedRef } from "@src/global_functions/hooks";

type DropdownWrapperProps = {
  children: React.ReactNode;
  as: React.ReactElement;
  disabled?: boolean;
  maxHeight?: number;
  fullWidth?: boolean;
  minWidth?: number;
  positionRelative?: boolean;
  /**
   * "positionRelative"
   * When true, no portal will be used and the dropdown will
   * render normally in the parent container expanding it if necessary.
   */
  innerClassName?: string;
  allowRenderAbove?: boolean;
  extraRefs?: React.RefObject<HTMLElement>[];
  extraDropdownRef?: React.RefObject<HTMLDivElement>;
  closeSubscription?: SubscribeFn;

  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;

  onOpen?: () => void;
  onClose?: () => void;
  onTransitionEnd?: (newOpenState: boolean) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const ESCAPE_KEYCODE = 27;

export default function DropdownWrapper(props: Readonly<DropdownWrapperProps>) {
  const [_menuOpened, _setMenuOpened] = React.useState(props.open ?? false);
  const menuOpened = props.open ?? _menuOpened;
  const setMenuOpened = props.setOpen ?? _setMenuOpened;

  const { onClose, onOpen } = props;

  useEffect(() => {
    if (menuOpened) {
      onOpen?.();
    } else {
      onClose?.();
    }
  }, [onOpen, onClose, menuOpened]);

  const containerRef = useRef<HTMLElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const refs = useMemo(
    () => [containerRef, dropdownRef, ...(props.extraRefs ?? [])],
    [props.extraRefs]
  );

  useEffect(() => {
    if (menuOpened) {
      const onDocumentClick = (e: MouseEvent) => {
        if (
          refs.every(
            (ref) => ref.current && !ref.current.contains(e.target as Node)
          )
        ) {
          setMenuOpened(false);
          onClose?.();
        }
      };
      document.addEventListener("mousedown", onDocumentClick);
      return () => {
        document.removeEventListener("mousedown", onDocumentClick);
      };
    }
  }, [menuOpened, refs, onClose, setMenuOpened]);

  useEffect(() => {
    const listener = (e: any) => {
      if (e.keyCode === ESCAPE_KEYCODE) setMenuOpened(false);
    };
    window.addEventListener("keyup", listener);
    return () => window.removeEventListener("keyup", listener);
  }, [setMenuOpened]);

  const { closeSubscription } = props;
  useEffect(
    () => closeSubscription?.(() => setMenuOpened(false)),
    [closeSubscription, setMenuOpened]
  );

  const root = document.getElementById(PORTAL_ROOT_ID);

  React.useEffect(() => {
    if (props.disabled) {
      setMenuOpened(false);
    }
  }, [props.disabled, setMenuOpened]);

  // Adding additional transitionState to prevent removing from DOM before onTransitionEnd is called
  // This way the Dropdown will be removed from DOM on the subsequent render
  const [hasExited, setHasExited] = React.useState(!menuOpened);
  const _onTransitionEnd = props.onTransitionEnd;
  const onTransitionEnd = React.useCallback(
    (newOpenState: boolean) => {
      if (!newOpenState) {
        setHasExited(true);
      } else {
        setHasExited(false);
      }
      _onTransitionEnd?.(newOpenState);
    },
    [_onTransitionEnd]
  );

  // force show on first render if open is true
  useEffect(() => {
    menuOpened && _setMenuOpened((prev) => !prev);
  }, []); // eslint-disable-line

  return (
    <>
      {React.cloneElement(props.as, {
        onClick: () => {
          if (!props.disabled) {
            setMenuOpened((prev) => !prev);
          }
        },
        onFocus: props.onFocus,
        onBlur: props.onBlur,
        ref: containerRef,
      })}
      {props.positionRelative ? (
        <CollapsibleContainer
          open={menuOpened}
          onTransitionEnd={onTransitionEnd}
        >
          <InnerContainer
            className={props.innerClassName}
            maxHeight={props.maxHeight}
          >
            {props.children}
          </InnerContainer>
        </CollapsibleContainer>
      ) : (
        <Transition
          in={menuOpened}
          timeout={{
            enter: 0,
            exit: 250,
          }}
        >
          {(transitionState) =>
            !(hasExited && transitionState === "exited") &&
            containerRef.current &&
            root &&
            createPortal(
              <Container
                transitionState={transitionState}
                fullWidth={props.fullWidth}
                minWidth={props.minWidth}
                maxHeight={props.maxHeight}
                allowRenderAbove={props.allowRenderAbove}
                containerRef={containerRef}
                dropdownRef={dropdownRef}
                extraDropdownRef={props.extraDropdownRef}
                innerClassName={props.innerClassName}
                onTransitionEnd={onTransitionEnd}
              >
                {props.children}
              </Container>,
              root
            )
          }
        </Transition>
      )}
    </>
  );
}

// dropdown menu fixed arbitrary values
const DROPDOWN_MENU_SPACE_BELOW_TO_SUBTRACT = 5;
const DROPDOWN_MENU_BOTTOM_BUFFER_HEIGHT = 30;
const DROPDOWN_MENU_MIN_HEIGHT = 200;
const DROPDOWN_MENU_RENDER_ABOVE_MAX_HEIGHT = 400;

function Container({
  children,
  maxHeight = 600,
  containerRef,
  dropdownRef,
  extraDropdownRef,
  transitionState,
  fullWidth,
  minWidth = 0,
  innerClassName,
  onTransitionEnd,
  allowRenderAbove = true,
}: {
  children: React.ReactNode;
  containerRef: React.RefObject<HTMLElement>;
  dropdownRef: React.RefObject<HTMLDivElement>;
  extraDropdownRef?: React.RefObject<HTMLDivElement>;
  maxHeight?: number;
  allowRenderAbove?: boolean;
  transitionState?: TransitionStatus;
  fullWidth?: boolean;
  minWidth?: number;
  innerClassName?: string;
  onTransitionEnd?: (newOpenValue: boolean) => void;
}) {
  const innerRef = React.useRef<HTMLDivElement | null>(null);

  /** measure the viewport, dropdown menu height and container element to determine the
  position (top or bottom) and height of the dropdown menu
  though the APIs are somewhat regularly used, mdn has a nice visual reference to help
  understand the measurements we're doing: https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect **/

  const getPosition = React.useCallback(() => {
    const {
      x: _x,
      y: _y,
      width: _width,
      height: _height,
    } = containerRef.current?.getBoundingClientRect() ?? {};

    const y = _y ?? 0;
    const x = _x ?? 0;
    const width = _width ?? 0;
    const height = _height ?? 0;

    const screenH = window.innerHeight;

    // space between container element and bottom of viewport
    const spaceBelow = screenH - y - DROPDOWN_MENU_SPACE_BELOW_TO_SUBTRACT;

    // dropdown menu height
    const rootH = dropdownRef?.current?.offsetHeight ?? 0;

    // does the height of the dropdown menu fit below?
    const fitsBelow = spaceBelow > rootH + DROPDOWN_MENU_BOTTOM_BUFFER_HEIGHT;

    if (dropdownRef.current && innerRef.current) {
      // set the menu to the width of the container element if fullWidth prop is passed
      if (fullWidth) {
        dropdownRef.current.style.width = `${Math.max(width, minWidth)}px`;
      } else {
        // maybe it shouldnt cap the width in cases like DatePicker?
        // dropdownRef.current.style.maxWidth = `${width}px`;
      }

      if (dropdownRef.current.offsetWidth + x < window.innerWidth) {
        // set the left position to the same as the container element
        dropdownRef.current.style.left = `${x}px`;
      } else {
        // set the right position to the same as the end of the container element
        dropdownRef.current.style.right = `${
          window.innerWidth - (x + (_width ?? 0))
        }px`;
      }

      // Set position to render below by default
      dropdownRef.current.style.top = `${y + height}px`;

      // set dropdown menu min height to prevent small overflow scroll window
      innerRef.current.style.minHeight = `${Math.min(
        DROPDOWN_MENU_MIN_HEIGHT,
        innerRef.current.offsetHeight
      )}px`;

      // account for the height of the container element, and set the max height to
      // make sure menu fits
      innerRef.current.style.maxHeight = `${Math.min(
        spaceBelow - height - DROPDOWN_MENU_BOTTOM_BUFFER_HEIGHT,
        maxHeight
      )}px`;

      // If doesnt fit below and space above is greater, then render above
      if (!fitsBelow && y > spaceBelow && allowRenderAbove) {
        innerRef.current.style.maxHeight = `${DROPDOWN_MENU_RENDER_ABOVE_MAX_HEIGHT}px`;
        dropdownRef.current.style.top = `${y - rootH}px`;
      }
    }
  }, [
    allowRenderAbove,
    containerRef,
    dropdownRef,
    fullWidth,
    maxHeight,
    minWidth,
  ]);

  const mergedDropdownRef = useMergedRef(dropdownRef, extraDropdownRef ?? null);

  React.useEffect(() => {
    window.addEventListener("wheel", getPosition);
    window.addEventListener("resize", getPosition);
    return () => {
      window.removeEventListener("wheel", getPosition);
      window.removeEventListener("resize", getPosition);
    };
  }, [getPosition]);

  React.useEffect(getPosition, [getPosition]);

  return (
    <div
      data-testid="straps-dropdown-wrapper-outer-container"
      className={classNames("fixed z-[1050] transition-opacity", {
        "opacity-100": transitionState === "entered",
        "opacity-0":
          transitionState === "exiting" ||
          transitionState === "exited" ||
          transitionState === "entering",
      })}
      ref={mergedDropdownRef}
      onTransitionEnd={(event) => {
        // prevent from firing on bubbled events from inside the container
        if (event.target === event.currentTarget) {
          onTransitionEnd?.(transitionState === "entered");
        }
      }}
    >
      <InnerContainer
        innerRef={innerRef}
        className={innerClassName}
        maxHeight={maxHeight}
      >
        {children}
      </InnerContainer>
    </div>
  );
}

function InnerContainer({
  innerRef,
  className,
  children,
  maxHeight,
  onTransitionEnd,
}: {
  innerRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  children: React.ReactNode;
  maxHeight?: number;
  onTransitionEnd?: () => void;
}) {
  return (
    <div
      ref={innerRef}
      data-testid="straps-dropdown-wrapper-inner-container"
      className={classNames(
        "flex flex-col overflow-auto bg-pure-white shadow-lg",
        className
      )}
      style={{
        maxHeight,
      }}
      onTransitionEnd={onTransitionEnd}
    >
      {children}
    </div>
  );
}
