import { encryptPassword } from "@src/global_functions/postgrestApi";
import { useMutation, useQuery, useQueryClient } from "react-query";

import FetchClients, { ClientOutput } from "../client";
import { UTILITY_ACCOUNTS_KEY } from "./accounts";

export type UtilityCredential = ClientOutput["utilities"]["UtilityCredential"];

export type CreateUtilityCredentialRequest =
  ClientOutput["utilities"]["CreateUtilityCredential"];

export type UpdateUtilityCredentialRequest =
  ClientOutput["utilities"]["UpdateUtilityCredential"];

export type UtilityAccountRequest =
  ClientOutput["utilities"]["UtilityAccountRequest"];

export type AccountToSelect =
  ClientOutput["utilities"]["ArcadiaAccountToSelect"];

const project = {
  useQueryAll,
  useQueryAccounts: useQueryAccounts,
  mutations: {
    usePost,
    usePut,
    usePostAccounts,
  },
} as const;

export default project;

export const UTILITY_CREDENTIALS_KEY = "utilities/credentials";

function useQueryAll(siteId: number, poll?: boolean) {
  return useQuery<UtilityCredential[]>({
    queryKey: [UTILITY_CREDENTIALS_KEY, siteId],
    queryFn: async () => {
      const { data, error } = await FetchClients.utilities.GET(
        "/{site_id}/credentials",
        {
          params: {
            path: {
              site_id: siteId,
            },
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    refetchInterval: poll ? 5000 : undefined,
  });
}

function usePost(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: CreateUtilityCredentialRequest) => {
      const { data, error } = await FetchClients.utilities.POST(
        "/{site_id}/credentials",
        {
          body: {
            ...body,
            password: await encryptPassword(body.password),
          },
          params: {
            path: {
              site_id: siteId,
            },
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [UTILITY_CREDENTIALS_KEY, siteId],
      }),
    onError: (e) => {
      throw new Error(
        `Unable to create new utility credential for site: ${siteId}
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
  });
}

function usePut(siteId: number, credentialId?: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: UpdateUtilityCredentialRequest) => {
      if (credentialId === undefined) {
        return;
      }
      const { error } = await FetchClients.utilities.PUT(
        "/{site_id}/credentials/{credential_id}",
        {
          body: {
            ...body,
            password: await encryptPassword(body.password),
          },
          params: {
            path: {
              site_id: siteId,
              credential_id: credentialId,
            },
          },
        }
      );
      if (error) {
        throw new Error(error);
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [UTILITY_CREDENTIALS_KEY, siteId],
      }),
    onError: (e) => {
      throw new Error(
        `Unable to create new utility credential for site: ${siteId}
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
  });
}

// After a credential is successfully added, accounts to select can be queried
function useQueryAccounts(siteId: number, credentialId: number) {
  return useQuery<AccountToSelect[]>(
    [UTILITY_CREDENTIALS_KEY, siteId, credentialId],
    async () => {
      const { data, error } = await FetchClients.utilities.GET(
        "/{site_id}/credentials/{credential_id}/accounts",
        {
          params: {
            path: {
              site_id: siteId,
              credential_id: credentialId,
            },
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    }
  );
}

function usePostAccounts(siteId: number, credentialId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: UtilityAccountRequest[]) => {
      const { error } = await FetchClients.utilities.POST(
        "/{site_id}/credentials/{credential_id}/accounts",
        {
          body,
          params: {
            path: {
              site_id: siteId,
              credential_id: credentialId,
            },
          },
        }
      );
      if (!error) return;
      throw new Error(error);
    },
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: [UTILITY_CREDENTIALS_KEY, siteId, credentialId],
      });
      queryClient.invalidateQueries({
        queryKey: [UTILITY_ACCOUNTS_KEY, siteId],
      });
    },
    onError: (e) => {
      throw new Error(
        `Unable to update accounts for site: ${siteId} and credential: ${credentialId}
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
  });
}
